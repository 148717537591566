import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar } from './components/navbar';
import { Home } from './components/home';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { AboutUs } from './components/about-us';
import { DoorLockMonitor } from './components/door-lock-monitor';
import { ContactUs } from './components/contact-us';

function App() {
  return (
    <div className='App'>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/door-lock-monitor" element={<DoorLockMonitor />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import { ImageBackGround } from '../image-background';
import SmoothScroll from "smooth-scroll";
import imageBg from '../../images/home.jpg'

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

export const Home = () => {
    
    return (
        <>
            <ImageBackGround imageBg={imageBg}/>
            <h1 style={{marginTop: '200px', textShadow: '1px 1px 32px black', color: '#ffc107', fontSize: '42px'}}>LOGIC AUTOMATION CORP</h1>
        </>
    );
};
import React from 'react'
// import { HashLink } from 'react-router-hash-link';
// import { HiMenu } from 'react-icons/hi';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from "react-router-dom";

export function NavBar() {
    const navegate = useNavigate();

    const gotoHome = () => {
        navegate('/home');
    }

    const gotoAboutUs = () => {
        navegate('/about-us');
    }

    const gotoDoorLockMonitor = () => {
        navegate('/door-lock-monitor');
    }

    const gotoContactUs = () => {
        navegate('/contact-us');
    }

    return (
        <Navbar
            // bg="light" 
            expand="lg"
            className="fixed-top transparent"
            style={{
                backgroundColor: 'white',
                //opacity: '0.9',
                //filter: '(opacity=50)',
            }}
        >
            <Container>
                <Navbar.Brand href="#home">LOGIC AUTOMATION CORP</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link onClick={gotoHome}>Home</Nav.Link>
                        <Nav.Link onClick={gotoAboutUs}>About Us</Nav.Link>
                        <NavDropdown title="Products" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={gotoDoorLockMonitor}>Door Lock Monitor</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Automation
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">
                                Consulting
                            </NavDropdown.Item>
                            {/* <NavDropdown.Divider /> */}
                            </NavDropdown>
                        <Nav.Link onClick={gotoContactUs}>Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

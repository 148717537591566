import React from 'react';
import imageBg from '../../images/contact-us.png';

export const ContactUs = () => {

    const myStyle = {
        marginTop: '80px',
        backgroundImage: `url(${imageBg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100px',
        width: '100vw',
    }

    return (
        <div style={myStyle}></div>
    )
}

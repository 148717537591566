import React from 'react';
import imageBg from '../../images/dlmflyer.png';

export const DoorLockMonitor = () => {

  const myStyle = {
    marginTop: '80px',
    backgroundImage: `url(${imageBg})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '1500px',
    width: '100vw',
  }

  return (
    <div style={myStyle}></div>
  )
}

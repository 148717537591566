import React from 'react';
import { ImageBackGround } from '../image-background';
import imageBg from '../../images/about-us.jpg';
import './index.css';

export const AboutUs = () => {
    return (
        <>
            <ImageBackGround imageBg={imageBg} />
            <h1 style={{ marginTop: '200px' }}>Under Construction</h1>
        </>
    );
}
